<template>
    <div id="CalendarsCustome">
        <div class="vx-row w-full">
        </div>
        <calendar style="height: 564px"
                  ref="tuiCal"
                  :useDetailPopup="useDetailPopup"
                  :view="selectedView"
                  :calendars="calendarList"
                  :schedules="scheduleList"
                  :taskView="false"
                  :scheduleView="true"
                  :month="month"
                  :week="week"
                  :disableDblClick="disableDblClick"
                  :isReadOnly="isReadOnly"
                  @clickSchedule="onClickSchedule"
                  @clickDayname="onClickDayname"
                  @beforeDeleteSchedule="onBeforeDeleteSchedule"
                  @afterRenderSchedule="onAfterRenderSchedule"
        />
    </div>
</template>

<script>
import 'tui-time-picker/dist/tui-time-picker.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-calendar/dist/tui-calendar.css';
import {Calendar} from '@toast-ui/vue-calendar';

const today = new Date();
const getDate = (type, start, value, operator) => {
    start = new Date(start);
    type = type.charAt(0).toUpperCase() + type.slice(1);
    if (operator === '+') {
        start[`set${type}`](start[`get${type}`]() + value);
    } else {
        start[`set${type}`](start[`get${type}`]() - value);
    }
    return start;
};
export default {
    name: "CalendarsCustome",
    props: ['selectedView', 'scheduleList'],
    data() {
        return {
            viewModeOptions: [
                {
                    title: 'Tháng',
                    value: 'month'
                },
                {
                    title: 'Tuần',
                    value: 'week'
                },
                {
                    title: 'Ngày',
                    value: 'day'
                }
            ],
            dateRange: '',
            calendarList: [],
            month: {
                startDayOfWeek: 0
            },
            week: {
                showTimezoneCollapseButton: true,
            },
            taskView: true,
            scheduleView: true,
            useDetailPopup: true,
            disableDblClick: true,
            isReadOnly: true,
        }
    },
    components: {
        'calendar': Calendar
    },
    watch: {
        selectedView(newValue) {
            this.$refs.tuiCal.invoke('changeView', newValue, true);
            this.setRenderRangeText();
        }
    },
    methods: {
        manageMeetingRoom() {
            let url = '/user/manage-meeting-room';
            if (this.branchId)
                url = `${url}`;
            this.$router.push(url).catch(() => {
            })
        },
        bookAMeeting() {
            let url = '/user/meeting-schedule';
            if (this.branchId)
                url = `${url}`;
            this.$router.push(url).catch(() => {
            })
        },
        init() {
            this.setRenderRangeText();
        },
        setRenderRangeText() {
            const {invoke} = this.$refs.tuiCal;
            const view = invoke('getViewName');
            const calDate = invoke('getDate');
            const rangeStart = invoke('getDateRangeStart');
            const rangeEnd = invoke('getDateRangeEnd');
            let year = calDate.getFullYear();
            let month = calDate.getMonth() + 1;
            let date = calDate.getDate();
            let dateRangeText = '';
            let endMonth, endDate, start, end;
            switch (view) {
                case 'month':
                    dateRangeText = `${year}-${month}`;
                    break;
                case 'week':
                    year = rangeStart.getFullYear();
                    month = rangeStart.getMonth() + 1;
                    date = rangeStart.getDate();
                    endMonth = rangeEnd.getMonth() + 1;
                    endDate = rangeEnd.getDate();
                    start = `${year}-${month}-${date}`;
                    end = `${endMonth}-${endDate}`;
                    dateRangeText = `${start} ~ ${end}`;
                    break;
                default:
                    dateRangeText = `${year}-${month}-${date}`;
            }
            this.dateRange = dateRangeText;
        },
        onClickNavi(event) {
            if (event.target.tagName === 'BUTTON') {
                const {target} = event;
                let action = target.dataset ? target.dataset.action : target.getAttribute('data-action');
                action = action.replace('move-', '');
                this.$refs.tuiCal.invoke(action);
                this.setRenderRangeText();
            }
        },
        onClickSchedule(res) {
            console.group('onClickSchedule');
            console.log('MouseEvent : ', res.event);
            console.log('Calendar Info : ', res.calendar);
            console.log('Schedule Info : ', res.schedule);
            console.groupEnd();
        },
        onClickDayname(res) {
            // view : week, day
            console.group('onClickDayname');
            console.log(res.date);
            console.groupEnd();
        },
        onBeforeDeleteSchedule(res) {


            console.group('onBeforeDeleteSchedule');
            console.log('Schedule Info : ', res.schedule);
            console.groupEnd();
            let idx = this.scheduleList.findIndex(item => item.id = res.schedule.__fe_id);
            // this.scheduleList.splice(idx, 1);
        },
        onAfterRenderSchedule(res) {
            console.group('onAfterRenderSchedule');
            console.log('Schedule Info : ', res.schedule);
            console.groupEnd();
        },
    },
    created() {
    },
    mounted() {
        this.init();
    },
}
</script>

<style scoped>

</style>
