<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div id="dashboard-analytics">
        <div class="vx-row">
            <!--      Total employee-->
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
                <div class="vx-card p-6 cursor employee" @click="goTotalEmployee">
                    <div class="vx-col">
                        <div class="float-right">
                            <div>
                                <h2 class="font-bold float-right totalEmployee">{{ totalEmployee }}</h2>
                            </div>
                            <label class="float-right">Nhân viên</label>
                        </div>
                        <feather-icon class="p-3 rounded-full text-primary"
                                      icon="UsersIcon" svgClasses="h-12 w-12 hover:text-danger cursor-pointer"/>
                    </div>
                </div>
            </div>
            <!--      Total employee-->
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
                <div class="vx-card p-6 cursor checkinok" @click="goIsCheckin(true)">
                    <div class="vx-col">
                        <div class="float-right">
                            <div>
                                <h2 class="font-bold float-right totalCheckin">{{ totalCheckin }}</h2>
                            </div>
                            <label class="float-right">Đã checkin</label>
                        </div>
                        <feather-icon class="p-3 rounded-full text-primary"
                                      icon="CheckCircleIcon" svgClasses="h-12 w-12 hover:text-danger cursor-pointer"/>
                    </div>
                </div>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
                <div class="vx-card p-6 cursor goIsLate" @click="goIsLate">
                    <div class="vx-col">
                        <div class="float-right">
                            <div>
                                <h2 class="font-bold float-right totalLate">{{ totalLate }}</h2>
                            </div>
                            <label class="float-right">Đi muộn</label>
                        </div>
                        <feather-icon class="p-3 rounded-full text-warning"
                                      icon="ClockIcon" svgClasses="h-12 w-12 hover:text-danger cursor-pointer"/>
                    </div>
                </div>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
                <div class="vx-card p-6 cursor goIsCheckin" @click="goIsCheckin(false)">
                    <div class="vx-col">
                        <div class="float-right">
                            <div>
                                <h2 class="font-bold float-right totalNotCheckin">{{ totalNotCheckin }}</h2>
                            </div>
                            <label class="float-right">Chưa checkin</label>
                        </div>
                        <feather-icon class="p-3 rounded-full text-error featherTotalNotCheckin"
                                      icon="XSquareIcon" svgClasses="h-12 w-12 hover:text-danger cursor-pointer"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full sm:w-1/4 w-full mb-base">
                <vx-card title="Checkin sớm" class="card-lave">
                    <div class="vx-card__title" style="color: rgb(184, 194, 204);">Nhân viên đi làm sớm
                        nhất <span class="hightlight_subtitle_soon">hôm nay.</span></div>
                    <div class="checkinsoon">
                        <div class="text-center" v-if="!isShowEmployeeEarly">
                            <div>
                                <feather-icon class="p-3 rounded-full opacity02"
                                              icon="ArchiveIcon" svgClasses="h-16 w-16"/>
                            </div>
                            <label>Trống</label>
                        </div>
                        <div slot="no-body" v-if="isShowEmployeeEarly">
                            <vs-table :data="employeeEarly" class="table-dark-inverted" id="checkin-soon">
                                <template slot="thead" v-if="false">
                                    <vs-th>Nhân viên</vs-th>
                                    <vs-th>Thời gian</vs-th>
                                </template>
                                <template slot-scope="{data}">
                                    <vs-tr :key="index" v-for="(item, index) in data">
                                        <vs-td class="template-vs-td-i">
                      <span class="cursor-pointer"
                            @click="goEmployeeTimeKeeping(item.employeeId)">{{ item.employeeCode }}_{{
                              item.fullName
                          }}</span>
                                        </vs-td>
                                        <vs-td>
                      <span class="cursor-pointer"
                            @click="goEmployeeTimeKeeping(item.employeeId)">{{ item.checkinTime }}</span>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                        </div>
                    </div>
                </vx-card>

            </div>
            <div class="vx-col w-full sm:w-3/4 w-full card-lave checkinweek">
                <vx-card title="Checkin" subtitle="Thời gian checkin trong 7 ngày.">
                    <vue-apex-charts v-if="isShowLineChartCheckin" type="line" height="210"
                                     :options="lineChartCheckin.chartOptions"
                                     :series="lineChartCheckin.series"></vue-apex-charts>
                </vx-card>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full sm:w-1/4 w-full mb-base">
                <vx-card title="Checkin muộn" class="card-lave">
                    <div class="vx-card__title" style="color: rgb(184, 194, 204);">Nhân viên đi làm muộn
                        nhất <span class="hightlight_subtitle_late">hôm qua.</span></div>
                    <div class="checkinlate">
                        <div class="text-center" v-if="!isShowEmployeeLate">
                            <div>
                                <feather-icon class="p-3 rounded-full opacity02"
                                              icon="ArchiveIcon" svgClasses="h-16 w-16"/>
                            </div>
                            <label>Trống</label>
                        </div>
                        <div slot="no-body" v-if="isShowEmployeeLate">
                            <vs-table :data="employeeLate" class="table-dark-inverted" id="checkin-late">
                                <template slot="thead" v-if="false">
                                    <vs-th>Nhân viên</vs-th>
                                    <vs-th>Thời gian</vs-th>
                                </template>

                                <template slot-scope="{data}">
                                    <vs-tr :key="index" v-for="(item, index) in data">
                                        <vs-td class="vs-td employeetime">
                    <span class="cursor-pointer"
                          @click="goEmployeeTimeKeeping(item.employeeId)">{{ item.employeeCode }}_{{
                            item.fullName
                        }}</span>
                                        </vs-td>
                                        <vs-td>
                    <span class="cursor-pointer"
                          @click="goEmployeeTimeKeeping(item.employeeId)">{{ item.checkinTime }}</span>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                        </div>
                    </div>
                </vx-card>
                <vx-card title="Xin nghỉ" subtitle="Nhân viên xin nghỉ." class="card-lave mt-8">
                    <div class="text-center" v-if="!isShowEmployeeLeave">
                        <div>
                            <feather-icon class="p-3  opacity02"
                                          icon="ArchiveIcon" svgClasses="h-16 w-16"/>
                        </div>
                        <label>Trống</label>
                    </div>
                    <div slot="no-body" class="p-6" v-if="isShowEmployeeLeave">
                        <VuePerfectScrollbar ref="scrollDialog"
                                             class="scroll-area--nofications-dropdown scroll p-0 mb-4"
                                             :settings="settings">
                            <div :key="index" class="isEmployeeLeave" v-for="(leave, index) in leaveData"
                                 @click="goLeave(leave.organizationBranchId, leave.id)">
                                <vx-tooltip class="f-r leave" :text="leave.employeeFullName" position="top">
                                    <vs-avatar size="90px" :src="leave.imgUrl"/>
                                </vx-tooltip>
                            </div>
                        </VuePerfectScrollbar>
                    </div>
                </vx-card>
            </div>
            <div class="vx-col w-full sm:w-2/5 w-full mb-base">
                <vx-card class="CalendarMeetingRoom" title="Phòng họp" subtitle="Lịch đặt phòng họp hôm nay.">
                    <!--          //Calendars-->
                    <CalendarsCustome
                        :selectedView="selectedViewDay"
                        :scheduleList="scheduleListDay"
                    />
                    <!--          //EndCalendars-->
                </vx-card>
            </div>
            <div class="vx-col w-full sm:w-1/3 w-full mb-base calendar-event">
                <vx-card class="CalendarEvent" title="Sự Kiện" subtitle="Tất cả sự̣ kiện trong tháng.">
                    <!--          //Calendars-->
                    <CalendarsCustome
                        :selectedView="selectedViewMonth"
                        :scheduleList="scheduleListMonth"
                    />
                    <!--          //EndCalendars-->
                </vx-card>
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
//Calender
import CalendarsCustome from "../components/CalendarsCustome.vue"
import VxTimeline from "../components/timeline/VxTimeline";
//EndCalender

export default {
    data() {
        return {
            //calender - Phòng họp
            selectedViewDay: 'day',
            scheduleListDay: [],
            //endCalendars - Phòng họp
            //Calendars - Sự kiện
            selectedViewMonth: 'month',
            scheduleListMonth: [],
            //endCalendars - Sự kiện
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: .30,
                suppressScrollX: true,
            },
            isShowBookMeeting: false,
            isShowEmployeeLeave: false,
            isShowLineChartCheckin: false,
            lineChartCheckin: {
                series: [],
                chartOptions: {
                    chart: {
                        redrawOnParentResize: true,
                        toolbar: {show: false},
                        dropShadow: {
                            enabled: true,
                            opacity: 0.10,
                        },
                        animations: {
                            enabled: false,
                        }
                    },
                    stroke: {
                        curve: 'smooth',
                        //dashArray: [0, 8],
                        width: [2, 2, 2, 2, 2, 2, 2, 2, 2,
                            2, 2, 2, 2, 2, 2, 2, 2
                            , 2, 2, 2, 2, 2, 2, 2, 2],
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                        yaxis: {
                            lines: {
                                show: false
                            }
                        }
                    },
                    legend: {
                        position: 'top',
                        show: true,
                    },
                    colors: ['#ff0000', '#3BD12F', '#6a06ee'],
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'dark',
                            inverseColors: false,
                            gradientToColors: ['#ff0000', '#12f300', '#6a06ee'],
                            shadeIntensity: 1,
                            type: 'horizontal',
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100, 100, 100]
                        },
                    },
                    markers: {
                        size: 0,
                        hover: {
                            size: 5
                        }
                    },
                    xaxis: {
                        categories: [],
                    },
                }
            },
            isShowEmployeeEarly: false,
            isShowEmployeeLate: false,
            totalEmployee: 0,
            totalCheckin: 0,
            totalLate: 0,
            totalNotCheckin: 0,
            leaveData: [],
            employeeEarly: [],
            employeeLate: [],
            roomMeeting: [],
            employees: [],
            bookroomMeetings: [],
            organizationBranchOptions: [],
        }
    },
    components: {
        VxTimeline,
        CalendarsCustome,
        VueApexCharts,
        VuePerfectScrollbar,
    },
    methods: {
        goEmployeeTimeKeeping(id) {
            this.$router.push(`/user/time-keeping?employeeId=${id}`).catch(() => {
            })
        },
        goTotalEmployee() {
            this.$router.push(`/user/manage-employee`).catch(() => {
            })
        },
        goIsCheckin(isCheckin) {
            this.$router.push(`/user/time-keeping?isCheckin=${isCheckin}`).catch(() => {
            })
        },
        goIsLate() {
            this.$router.push(`/user/time-keeping?isLate=true`).catch(() => {
            })
        },
        goLeave(organizationBranchId, id) {
            this.$router.push(`/user/edit-leave?branch_id=${organizationBranchId}&id=${id}`).catch(() => {
            });
        },
        getLeave() {
            this.$vs.loading();
            this.$vs.loading();
            let path = '/employee/all';
            this.$crm.post(path).then((response) => {
                this.employees = response.data;
                this.$crm.post('/dashboard/today-leave').then((response) => {
                    this.leaveData = response.data.map(item => {
                        if (item.status == 0) item.displayStatus = "Đang chờ"
                        if (item.status == 1) item.displayStatus = "Đã duyệt"
                        if (item.status == 2) item.displayStatus = "Từ chối"
                        if (item.status == 3) item.displayStatus = "Hủy"
                        item.displayLeaveDate = moment(new Date(item.fromDate)).format("DD/MM/YYYY") + ' - ' + moment(new Date(item.toDate)).format("DD/MM/YYYY");
                        let employee = this.employees.find(x => x.employeeId == item.employeeId);
                        if (employee && employee.imageStore && employee.imageStore.length > 0) {
                            item.imgUrl = employee.imageStore[0].url;
                        }
                        return item;
                    });
                    if (this.leaveData.length > 0) this.isShowEmployeeLeave = true;
                    this.$vs.loading.close();
                });
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getTotalEmployee() {
            this.$vs.loading();
            this.$crm.get('/employee/total').then((response) => {
                this.totalEmployee = response.data;
                this.$vs.loading.close();
            }).catch(() => {
                return this.$vs.notify({
                    text: "Có lỗi lấy tổng số nhân viên.",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getTotalCheckin() {
            this.$vs.loading();
            this.$crm.get('/time-keeping/count-total-checkin').then((response) => {
                this.totalCheckin = response.data;
                this.$vs.loading.close();
            }).catch(() => {
                return this.$vs.notify({
                    text: "Có lỗi lấy tổng số nhân viên checkin.",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getTotalLate() {
            this.$vs.loading();
            this.$crm.get('/time-keeping/count-total-late').then((response) => {
                this.totalLate = response.data;
                this.$vs.loading.close();
            }).catch(() => {
                return this.$vs.notify({
                    text: "Có lỗi lấy tổng số nhân viên đi muộn.",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getTotalNotCheckin() {
            this.$vs.loading();
            this.$crm.get('/time-keeping/count-total-not-checkin').then((response) => {
                this.totalNotCheckin = response.data;
                this.$vs.loading.close();
            }).catch(() => {
                return this.$vs.notify({
                    text: "Có lỗi lấy tổng số nhân viên chưa checkin.",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getEmployeeEarly() {
            this.$vs.loading();
            this.$crm.get('/time-keeping/employee-early').then((response) => {
                this.employeeEarly = response.data;
                if (this.employeeEarly.length > 0) this.isShowEmployeeEarly = true;
                this.$vs.loading.close();
            }).catch(() => {
                return this.$vs.notify({
                    text: "Có lỗi lấy tổng số nhân viên đến sớm.",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getEmployeeLate() {
            this.$vs.loading();
            this.$crm.get('/time-keeping/employee-late').then((response) => {
                this.employeeLate = response.data;
                if (this.employeeLate.length > 0) this.isShowEmployeeLate = true;
                this.$vs.loading.close();
            }).catch(() => {
                return this.$vs.notify({
                    text: "Có lỗi lấy tổng số nhân viên đi muộn.",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getCheckinDayByDay() {
            let data = {
                startDate: new Date(new Date().getTime() - (60 * 60 * 24 * 1000 * 6)),
                endDate: new Date()
            }
            this.$vs.loading();
            this.$crm.post('/time-keeping/checkin-day-by-day', data).then((response) => {
                this.lineChartCheckin.chartOptions.xaxis.categories = response.data
                    .map(x => moment(x.currentDate, 'YYYY-MM-DD').format('DD/MM'));
                let employeeNotCheckin = response.data.map(x => x.employeeNotCheckin);
                let employeeCheckinEarly = response.data.map(x => x.employeeCheckinEarly);
                let employeeCheckinLate = response.data.map(x => x.employeeCheckinLate);
                this.lineChartCheckin.series = [
                    {name: "Không checkin", data: employeeNotCheckin},
                    {name: "Đi sớm", data: employeeCheckinEarly},
                    {name: "Đi muộn", data: employeeCheckinLate}
                ]
                this.isShowLineChartCheckin = true;
                this.$vs.loading.close();
            }).catch(() => {
                return this.$vs.notify({
                    text: "Có lỗi lấy tổng số nhân viên chưa checkin.",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        async getbookroomMeetings() {
            await this.getMeetingRoom();
            this.$crm.get('/reservation').then((response) => {
                response.data.forEach(x => {
                    let room = this.roomMeeting.find(room => x.roomId == room.id);
                    if (room) x.roomName = room.name;
                    if (x.dateBegin && x.dateEnd) {
                        let minutesBeginString = new Date(x.dateBegin).getMinutes() != 0 ? (":" + new Date(x.dateBegin).getMinutes()) : ':00';
                        x.dateBeginString = new Date(x.dateBegin).getHours() + minutesBeginString;
                        let minutesDateEndString = new Date(x.dateEnd).getMinutes() != 0 ? (":" + new Date(x.dateEnd).getMinutes()) : ':00';
                        x.dateEndString = new Date(x.dateEnd).getHours() + minutesDateEndString;
                        x.dateString = x.dateBeginString + " - " + x.dateEndString;
                    }
                });
                this.bookroomMeetings = response.data;
                if (this.bookroomMeetings.length > 0) this.isShowBookMeeting = true;
            }).catch(() => {
                return this.$vs.notify({
                    text: "Có lỗi lấy phòng họp.",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getMeetingRoom() {
            return new Promise(resolve => {
                this.$vs.loading();
                this.$crm.get('/meeting-room/get').then((response) => {
                    this.roomMeeting = response.data;
                    this.organizationBranchOptions = response.data.map(itembranch => {
                        return {
                            value: itembranch.organizationId,
                            label: itembranch.location,
                        }
                    });
                    resolve(true);
                    this.$vs.loading.close();
                }).catch(() => {
                    return this.$vs.notify({
                        text: "Có lỗi lấy phòng họp.",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },
        async getDayMeetingRom() {
            await this.getMeetingRoom();
            this.$vs.loading();
            this.$crm.get('/reservation/all').then((response) => {
                this.scheduleListDay = response.data.map(item => {
                    if (item.capacity == null) {
                        item.capacity = '0';
                    }
                    if (item.reservationName == null) {
                        item.reservationName = 'Không có tên cuộc họp';
                    }
                    if (item.purpose == null) {
                        item.purpose = '';
                    }
                    let locations = this.organizationBranchOptions.map(branch => {
                        return branch.label
                    })
                    return {
                        title: item.reservationName,
                        body: 'Mục đích: ' + item.purpose + '.',
                        location: locations,
                        category: 'time',
                        dueDateClass: "",
                        recurrenceRule: 'Người tham dự: ' + item.participants.map(i => {
                            return i.fullName
                        }) + '.',
                        start: new Date(item.dateBegin),
                        end: new Date(item.dateEnd),
                        borderColor: 'red',
                        bgColor: '#059862a8',
                        state: 'Số người tham dự: ' + item.capacity + ' người.'
                    }
                });
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    },
    created() {
        this.getDayMeetingRom();
        this.getTotalEmployee();
        this.getTotalCheckin();
        this.getTotalLate();
        this.getTotalNotCheckin();
        this.getLeave();
        this.getEmployeeEarly();
        this.getEmployeeLate();
        this.getCheckinDayByDay();
        this.getbookroomMeetings();
    },
}
</script>

<style lang="scss" scoped>
.checkinsoon {
    margin-top: 1rem;
}

.checkinlate {
    margin-top: 1rem;
}

@media only screen and (max-width: 1575px) {
    .checkinsoon {
        margin-top: 0rem;
    }
    .checkinlate {
        margin-top: 0rem;
    }
}

.hightlight_subtitle_soon {
    color: rgba(var(--vs-primary),1);
}

.hightlight_subtitle_late {
    color: rgba(var(--vs-primary),1);
}

.scroll {
    max-height: 17rem !important;
}

.booking {
    .vs-avatar--con-img {
        border-radius: 10px !important;
    }

    .con-vs-avatar {
        width: 100% !important;
    }
}

/*.leave {*/
/*  .vs-avatar--con-img {*/
/*    border-radius: 10px !important;*/
/*  }*/
/*  .con-vs-avatar {*/
/*    width: 100%!important;*/
/*  }*/
/*}*/
.vs-con-tbody:hover {
    overflow-y: auto !important;
}

.cursor {
    cursor: pointer !important;
}

.card-lave {
    min-height: 330px !important;
    max-height: 330px !important;
}

#dashboard-analytics {
    h4 {
        font-weight: bold !important;
        color: #000000 !important;
    }

    .greet-user {
        position: relative !important;

        .decore-left {
            position: absolute !important;
            left: 0 !important;
            top: 0 !important;
        }

        .decore-right {
            position: absolute !important;
            right: 0 !important;
            top: 0 !important;
        }
    }

    @media(max-width: 576px) {
        .decore-left, .decore-right {
            width: 140px !important;
        }
    }
}

/*! rtl:end:ignore */
.employee {
    background-color: #cce5ff !important;
}

.totalEmployee {
    font-weight: 700 !important;
}

.checkinweek {
    min-height: 330px !important;
}

.employeetime {
    width: 250px !important;
}

.opacity02 {
    opacity: 0.2 !important;
}

.checkinok {
    background-color: #d4edda !important;
}

.totalCheckin {
    font-weight: 700 !important;
}

.goIsLate {
    background-color: #F8F2D7 !important;
}

.totalLate {
    font-weight: 700 !important;
}

.goIsCheckin {
    background-color: #f8d7da !important;
}

.totalNotCheckin {
    font-weight: 700 !important;
}

.featherTotalNotCheckin {
    color: #ff0000 !important;
}

.template-vs-td-i {
    width: 18rem !important;
}

.isEmployeeLeave {
    float: left !important;
    padding: 5px !important;
}

.CalendarMeetingRoom {
    height: 688px !important;
}

.calendar-event {
    width: 35% !important;
}

.CalendarEvent {
    height: 688px !important;
}
</style>
